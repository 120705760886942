import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./ServiceDetail.css";

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const [service, setService] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await axios.get(
          `https://construction-portal-backend.vercel.app/api/services/${serviceId}`
        );
        setService(response.data);
      } catch (error) {
        console.error("Error fetching service:", error);
        setError("Error fetching service details.");
      }
    };

    fetchService();
  }, [serviceId]);

  if (error) return <div>{error}</div>;
  if (!service) return <div>Loading...</div>;

  return (
    <div style={{ marginTop: "80px" }}>
      <div className="service-detail container">
        <section className="center-section">
          <h1>{service.title}</h1>
        </section>
        <section className="left-section">
          <div dangerouslySetInnerHTML={{ __html: service.content }}></div>
        </section>
        <section className="left-section">
          <h2>Gallery</h2>
          <div className="gallery">
            {service.images && Array.isArray(service.images) ? (
              service.images.map((image, index) => (
                <div className="gallery-item" key={index}>
                  <img
                    src={image.url}
                    alt={`Service  ${index + 1}`}
                    className="gallery-image"
                  />
                </div>
              ))
            ) : (
              <p>No images available</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServiceDetail;

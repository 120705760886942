import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios"; 
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Constructions from "./routes/Constructions";
import Services from "./routes/Services";
import ServiceDetail from "./routes/ServiceDetail";
import Projects from "./routes/Projects";
import Testimonials from "./routes/Testimonials";
import Blogs from "./routes/Blogs";
import Footer from "./components/Footer";
import Contact from "./routes/Contact";
import AboutUs from "./routes/AboutUs";
import ErrorPage from "./components/Error404";
import AdminLogin from "./routes/AdminLogin";
import Dashboard from "./Admin/Dashboard";
import AdminServices from "./Admin/AdminServices";
import AdminProjects from "./Admin/AdminProjects";
import AdminBlogs from "./Admin/AdminBlogs";
import AdminConstructions from "./Admin/AdminConstructions";
import AdminTestimonials from "./Admin/AdminTestimonials";
import AdminHero from "./Admin/AdminHero";
import AdminAboutUs from "./Admin/AdminAboutUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from './components/Loading'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serverStatus, setServerStatus] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const fetchServerStatus = async () => {
      try {
        const response = await axios.get("https://construction-portal-backend.vercel.app/api/status");
        setServerStatus(response.data);
      } catch (error) {
        console.error("Error fetching server status:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServerStatus();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/constructions" element={<Constructions />} />
          <Route path="/services/:serviceId" element={<ServiceDetail />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="*" element={<ErrorPage />} />

          {/* Admin routes */}
          <Route
            path="/admin"
            element={
              !isLoggedIn ? (
                <AdminLogin setIsLoggedIn={setIsLoggedIn} />
              ) : (
                <Navigate to="/admin/dashboard" />
              )
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              isLoggedIn ? (
                <Dashboard
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
              ) : (
                <Navigate to="/admin" />
              )
            }
          >
            <Route path="services" element={<AdminServices />} />
            <Route path="projects" element={<AdminProjects />} />
            <Route path="blogs" element={<AdminBlogs />} />
            <Route path="constructions" element={<AdminConstructions />} />
            <Route path="testimonials" element={<AdminTestimonials />} />
            <Route path="hero" element={<AdminHero />} />
            <Route path="aboutus" element={<AdminAboutUs />} />
          </Route>
        </Routes>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
